import { RouteRecordRaw } from 'vue-router'

import NotFound from '@/pages/NotFound.vue'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/auth/login',
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/auth/:type',
    name: 'auth',
    component: () => import('@/pages/Auth.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/pages/Dashboard.vue'),
  },
  {
    path: '/protected/new',
    name: 'NewProtected',
    component: () => import('@/pages/protected/NewProtected.vue'),
  },
  {
    path: '/campaigns/new',
    name: 'NewCampaign',
    component: () => import('@/pages/campaigns/NewCampaign.vue'),
  },
  {
    path: '/delivery/:token',
    name: 'Delivery',
    component: () => import('@/pages/fake-pages/Delivery.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      layout: 'empty',
    },
  },
]
