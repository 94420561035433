import { createRouter, createWebHistory } from 'vue-router'

import { routes } from '@/router/routes.ts'

declare module 'vue-router' {
  interface RouteMeta {
    layout?: 'default' | 'empty'
  }
}

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})
