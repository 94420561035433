import axios from 'axios'
import { useRouter } from 'vue-router'

const baseURL = import.meta.env.VITE_API_URL || 'http://localhost:3000/api'

export const apiClient = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
})

// Add response interceptor for error handling
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      const router = useRouter()

      if (!router.currentRoute.value.meta.isPublic) {
        router.push('/auth/login')
      }
    }
    return Promise.reject(error)
  }
)
