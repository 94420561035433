<script setup lang="ts">
import { Moon, Sun } from 'lucide-vue-next'
import { Button } from '@/components/ui/button'
import { useTheme } from '@/composables/useTheme'

const { isDark, toggleTheme } = useTheme()
</script>

<template>
  <Button variant="ghost" size="icon" @click="toggleTheme" class="relative">
    <Sun
      class="h-5 w-5 rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0"
      :class="{ 'dark:-rotate-90 dark:scale-0': isDark }"
    />
    <Moon
      class="absolute h-5 w-5 rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100"
      :class="{ 'dark:rotate-0 dark:scale-100': isDark }"
    />
    <span class="sr-only">Toggle theme</span>
  </Button>
</template>

<style scoped>
.transition-all {
  transition-duration: 200ms;
}
</style>
