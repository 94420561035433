<script setup lang="ts">
import { Toaster } from '@/components/ui/sonner'
import NavBar from '@/components/NavBar.vue'
import { useAuth } from '@/composables/useAuth'
import { useUserMeQuery } from '@/composables/useUserMe'
import { useRouter } from 'vue-router'

const { isAuthenticated } = useAuth()
const router = useRouter()

const { data: user } = useUserMeQuery()
</script>

<template>
  <NavBar v-if="isAuthenticated && user?.isPhoneVerified" />
  <RouterView />
  <Toaster />
</template>

<style scoped></style>
