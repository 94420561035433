import posthog, { PostHog } from 'posthog-js'
import { App } from 'vue'

import { User } from '@/types/user.ts'
import { JsonObject } from '@/types/json-type.ts'
import { IAnalyticsHandler } from '@/types/analytics-handler-interface'

export class PosthogAnalytics implements IAnalyticsHandler {
  private static _instance?: PostHog

  install(_app: App): void {
    if (!PosthogAnalytics._instance) {
      const posthogToken = import.meta.env.VITE_POSTHOG_KEY
      const posthogHost = import.meta.env.VITE_POSTHOG_HOST

      const instance = posthog.init(posthogToken, {
        api_host: posthogHost,
        session_recording: {
          maskAllInputs: false,
          maskInputOptions: {
            password: true,
            color: false,
            date: false,
            'datetime-local': false,
            email: false,
            month: false,
            number: false,
            range: false,
            search: false,
            tel: false,
            text: false,
            time: false,
            url: false,
            week: false,
            textarea: false,
            select: false,
          },
        },
      })

      if (instance) {
        PosthogAnalytics._instance = instance
      }
    }
  }

  identify(userId: string, data: User): void {
    if (PosthogAnalytics._instance) {
      const enrichData = { email: data.email }

      PosthogAnalytics._instance.identify(userId, enrichData)
    }
  }

  getRouteHandler = (url: string, _properties?: object): any => {
    this.trackPageView(url)
  }

  trackEvent(name: string, data: JsonObject, _userId?: string): void {
    if (PosthogAnalytics._instance) {
      PosthogAnalytics._instance.capture(name, { ...data })
    }
  }

  trackPageView(url: string | undefined, _properties?: object): void {
    if (PosthogAnalytics._instance) {
      PosthogAnalytics._instance.capture('$pageview', { $current_url: url })
    }
  }

  logout(): void {
    if (PosthogAnalytics._instance) {
      PosthogAnalytics._instance.reset()
    }
  }
}
