import { inject } from 'vue'

import AnalyticsService from '@/services/analytics/analytics-service.ts'

export function useAnalytics(): AnalyticsService {
  const analytics = inject('analytics')

  if (!analytics) {
    throw new Error('FATAL ERROR: Analytics service is not provided.')
  }

  return analytics as AnalyticsService
}
