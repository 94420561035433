import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import { router } from '@/router/router.ts'
import vue3GoogleLogin from 'vue3-google-login'
import { QueryClient, VueQueryPlugin } from '@tanstack/vue-query'
import analyticsPlugin from '@/plugins/analytics-plugin'
import '@/assets/index.css'

const app = createApp(App)

app.use(analyticsPlugin)

app.use(router)

app.use(vue3GoogleLogin, {
  clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
    mutations: {
      retry: 0,
    },
  },
})

app.use(VueQueryPlugin, {
  enableDevtoolsV6Plugin: true,
  queryClient,
})

app.mount('#app')
