<script setup lang="ts">
import { useRoute } from 'vue-router';


const route = useRoute();
const notFoundPath = route.params.pathMatch as Array<string>;
</script>

<template>
  <div class="flex h-[100dvh] w-[100dvw] flex-col items-center justify-center">
    <h1 class="text-6xl">404</h1>
    <p class="text-lg">{{ notFoundPath.join('/') }} not found</p>
    <router-link to="/">Go to home</router-link>
  </div>
</template>
