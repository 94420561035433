import { nextTick, Ref, ref } from 'vue'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { apiClient } from '@/services/api-client'
import { useRouter } from 'vue-router'
import type { LoginCredentials, User } from '@/types/auth'
import type { RegisterPayload } from '@/types/auth'
import { useAnalytics } from '@/composables/useAnalytics'

const isAuthenticated: Ref<boolean> = ref(false)

export function useAuth() {
  const analytics = useAnalytics()
  const router = useRouter()
  const queryClient = useQueryClient()

  const identifyUserOnVendorServices = (user: User) => {
    analytics.identify(user.id, user)
  }

  const setAuthState = async (state: boolean) => {
    const previousState = isAuthenticated.value

    isAuthenticated.value = state

    if (previousState && !state) {
      await nextTick()
      await queryClient.resetQueries({ predicate: (query) => query.meta?.authRequired === true })
    }
  }

  const sendOTPMutation = useMutation({
    mutationFn: async (phoneNumber: string) => {
      const { data } = await apiClient.post('/auth/otp/send', { phoneNumber })
      return data
    },
  })

  const verifyOTPMutation = useMutation({
    mutationFn: async (payload: { otpCode: string; authenticationUuid: string }) => {
      const { data } = await apiClient.post('/auth/otp/verify', payload)
      return data
    },
  })

  const loginMutation = useMutation({
    mutationFn: async (credentials: LoginCredentials) => {
      const { data } = await apiClient.post<User>('/auth/login', credentials)
      return data
    },
    onSuccess: (data) => {
      identifyUserOnVendorServices(data)
    },
  })

  const registerMutation = useMutation({
    mutationFn: async (payload: RegisterPayload) => {
      const { data } = await apiClient.post<User>('/auth/register', payload)
      return data
    },
    onSuccess: (data) => {
      identifyUserOnVendorServices(data)
    },
  })

  const googleLoginMutation = useMutation({
    mutationFn: async (credential: string) => {
      const { data } = await apiClient.get<User>('/auth/google/authorize', {
        params: { credential },
      })
      return data
    },
    onSuccess: (data) => {
      identifyUserOnVendorServices(data)
    },
  })

  const logoutMutation = useMutation({
    mutationFn: async () => {
      await apiClient.post('/auth/logout')
    },
    onSuccess: () => {
      setAuthState(false)
      router.push('/auth/login')
    },
  })

  return {
    registerMutation,
    setAuthState,
    isAuthenticated,
    loginMutation,
    googleLoginMutation,
    sendOTPMutation,
    verifyOTPMutation,
    logoutMutation,
    identifyUserOnVendorServices,
  }
}
