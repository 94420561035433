import { useQuery } from '@tanstack/vue-query'
import { apiClient } from '@/services/api-client'
import type { User } from '@/types/auth'
import { useAuth } from './useAuth'
import { useRouter } from 'vue-router'

export function useUserMeQuery() {
  const { setAuthState } = useAuth()
  const router = useRouter()

  return useQuery({
    queryKey: ['users', 'me'],
    meta: { authRequired: true },
    queryFn: async () => {
      try {
        const { data: user } = await apiClient.get<User>('/users/me')
        setAuthState(true)

        if (!user.isPhoneVerified) {
          router.push('/auth/login')
        }

        return user
      } catch {
        setAuthState(false)

        await router.isReady()

        if (!router.currentRoute.value.meta.isPublic) {
          router.push('/auth/login')
        }

        return null
      }
    },
  })
}
