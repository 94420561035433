<script setup lang="ts">
import { useAuth } from '@/composables/useAuth'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'
import { UserCircle } from 'lucide-vue-next'
import ThemeSwitcher from '@/components/ThemeSwitcher.vue'
import Logo from '@/assets/images/logo.svg'
import { useUserMeQuery } from '@/composables/useUserMe'

const { logoutMutation } = useAuth()
const { data: user } = useUserMeQuery()

const handleLogout = async () => {
  await logoutMutation.mutateAsync()
}
</script>

<template>
  <nav class="border-b">
    <div class="flex h-16 items-center justify-between px-4">
      <div class="flex items-center gap-2">
        <Logo class="w-24 h-24" />
      </div>

      <div class="flex items-center gap-2">
        {{ user?.name }}
        <ThemeSwitcher />
        <DropdownMenu>
          <DropdownMenuTrigger as-child>
            <Button variant="ghost" size="icon">
              <UserCircle class="h-8 w-8" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <!-- <DropdownMenuItem>Settings</DropdownMenuItem> -->
            <DropdownMenuItem @click="handleLogout">Logout</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  </nav>
</template>
