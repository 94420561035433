import { App } from 'vue'

import AnalyticsService from '@/services/analytics/analytics-service.ts'
import { PosthogAnalytics } from '@/services/analytics/posthog-handler.ts'
import { IntercomAnalytics } from '@/services/analytics/intercom-handler'

export default {
  install(app: App): void {
    const analytics = new AnalyticsService([new PosthogAnalytics(), new IntercomAnalytics()])

    analytics.install(app)

    app.provide('analytics', analytics)
  },
}
